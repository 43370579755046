import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import "./photo.css";
import downloadImg from "../../style/assets/download.png";
import reloadImg from "../../style/assets/reload.png";

declare global {
	interface Window {
		globalConfig: any;
	}
}

const REACT_APP_API_URL = window.globalConfig?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

export default function Photo(props: any) {
	let { id } = useParams();

	const [exists, setExists] = useState(true);

	const [takes, setTakes] = useState<any>([]);

	const [takeSize, setTakeSize] = useState(0);
	const _takeSize = useRef(takeSize);

	useEffect(() => {
		_takeSize.current = takeSize;
	}, [takeSize]);

	const [urlObjectGlobal, setUrlObject] = useState("");
	const _urlObjectGlobal = useRef(urlObjectGlobal);

	useEffect(() => {
		_urlObjectGlobal.current = urlObjectGlobal;
	}, [urlObjectGlobal]);

	useEffect(() => {
		for (let t of takes) {
			let urlTake;

			if (typeof t.session === "string" || t.session instanceof String) {
				urlTake = REACT_APP_API_URL + "/data/print/" + t.session + "/" + t._id + "";
			} else {
				urlTake = REACT_APP_API_URL + "/data/print/" + t.session._id + "/" + t._id + "";
			}

			axios({
				url: urlTake,
				method: "HEAD",
			})
				.then((response) => {
					if (response.headers["content-length"]) {
						setTakeSize(response.headers["content-length"]);
					}

					axios({
						url: urlTake,
						method: "GET",
						responseType: "blob",
						onDownloadProgress: (dl: any) => {
							console.log("DL:", dl, _takeSize.current, (dl.loaded * 100) / _takeSize.current);
							let elem = document.getElementById("loadingBar");

							if (elem) {
								elem.style.width = (_takeSize.current, (dl.loaded * 100) / _takeSize.current) + "%";
							}
						},
					}).then((response) => {
						var blob = new Blob([response.data], { type: "image/jpeg" });
						let urlObject = webkitURL.createObjectURL(blob);

						let photo = document.getElementById("photo");

						setUrlObject(urlObject);

						if (photo) {
							(photo as HTMLVideoElement).style.backgroundImage = "url(" + urlObject + ")";

							let downloadButton = document.getElementById("downloadButton");

							if (downloadButton) {
								downloadButton.style.opacity = "1";
							}

							let elem = document.getElementById("loadingBarContainer");

							if (elem) {
								elem.style.opacity = "0";
							}
						}
					});
				})
				.catch(() => {
					console.log("Can't get length.");
					setExists(false);
				});
		}
	}, [takes]);

	const [dlName, setDlName] = useState("MagnicityPhoto");

	useEffect(() => {
		console.log("idTake=", id);

		let urlTake = REACT_APP_API_URL + "/take/get/" + id;

		axios({
			url: urlTake,
			method: "GET",
		})
			.then((response) => {
				console.log("Download end", response);
				if (response.data.exist) {
					setTakes([response.data.take]);
					setDlName(
						response.data.take.session.idExperience === "montparnassetower"
							? "MagnicityParisPhoto"
							: "MagnicityBerlinPhoto"
					);
				} else {
					console.log("Take doesn't exist");
					setExists(false);
				}
			})
			.catch(() => {
				console.log("Can't get take.");
				setExists(false);
			});
	}, []);

	console.log(_urlObjectGlobal.current);

	function downloadFile(fileName) {
		const aElement = document.createElement("a");
		aElement.setAttribute("download", fileName);

		aElement.href = _urlObjectGlobal.current;
		// aElement.setAttribute('href', href);
		aElement.setAttribute("target", "_blank");
		aElement.click();
		URL.revokeObjectURL(_urlObjectGlobal.current);
	}

	return (
		<div
			style={{
				display: "block",
				width: "100%",
				height: "100%",
			}}
		>
			<Helmet>
				<meta property='og:image' content={`https://api.capture.timescope.com/data/mini/${id}`} />
			</Helmet>
			{exists && (
				<div
					id='downloadButton'
					onClick={() => {
						console.log("Go download");
						downloadFile(dlName + ".jpeg");
					}}
					style={{
						zIndex: 10,
						position: "absolute",
						width: "52px",
						height: "52px",
						top: "10px",
						right: "10px",
						pointerEvents: "all",

						backgroundImage: `url(${downloadImg})`,
						backgroundPosition: "center",
						backgroundSize: "40% 40%",
						backgroundRepeat: "no-repeat",

						borderRadius: "50%",
						border: "2px solid #6b7280",
						backgroundColor: "#111827",

						opacity: "0",
					}}
				></div>
			)}
			{!exists && (
				<div
					style={{
						zIndex: 10,
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translateY(-50%) translateX(-50%)",
						color: "white",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div>Please come back later</div>
					<div
						onClick={() => {
							console.log("Go reload !");
							window.location.reload();
						}}
						style={{
							zIndex: 10,
							margin: "12px 0 0 0",
							width: "52px",
							height: "52px",
							pointerEvents: "all",

							backgroundImage: `url(${reloadImg})`,
							backgroundPosition: "center",
							backgroundSize: "40% 40%",
							backgroundRepeat: "no-repeat",

							borderRadius: "50%",
							border: "2px solid #6b7280",
							backgroundColor: "#111827",
						}}
					></div>
				</div>
			)}
			{exists && (
				<div
					id='loadingBarContainer'
					style={{
						zIndex: 1,
						position: "absolute",
						width: "80%",
						height: "10px",
						backgroundColor: "#6b7280",
						top: "50%",
						left: "10%",
						transform: "translateY(-50%)",
					}}
				>
					<div
						id='loadingBar'
						style={{
							zIndex: 0,
							position: "absolute",
							width: "0%",
							height: "100%",
							left: "0",
							backgroundColor: "#F30C69",
						}}
					></div>
				</div>
			)}
			<div
				style={{
					display: id ? "block" : "none",
					width: "100%",
					height: "100%",
					backgroundPosition: "center",
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
				}}
				id={"photo"}
			></div>
		</div>
	);
}
