import React from "react";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";

import { default as Main } from "./components/Main/Main";
import { default as Viewer } from "./components/Viewer/Viewer";
import { default as Photo } from "./components/Photo/Photo";

let history = require("history").createBrowserHistory();

function App() {
	return (
		<HistoryRouter history={history}>
			<Routes>
				<Route path='/' element={<Main />} />
				<Route path='/v/:id' element={<Viewer />} />
				<Route path='/p/:id' element={<Photo />} />
			</Routes>
		</HistoryRouter>
	);
}

export default App;
